import {instance} from ".";

// ---

async function getUsersList() {
    return await instance.get(`/users`).then(response => response.data);
}

async function updateUserPassword(data: any) {
    return await instance.put(`/profile/user`, {
        "password": data.password,
        "password_confirmation": data.confirmation_password,
    }).then(response => response.data);
}

async function updateUserEmail(data: any) {
    return await instance.put(`/profile/user`, {
        "email": data.email,
    }).then(response => response.data);
}

export { getUsersList, updateUserPassword, updateUserEmail }