import PropTypes from 'prop-types';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Drawer, Typography } from '@mui/material';

import useResponsive from '../../../hooks/useResponsive';

import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';

import { navConfigAdmin, navConfigBureau, navConfigPublic } from './config';
import SvgColor from '../../../components/svg-color';
import { getRole } from '../../../api/auth';

// ---

const NAV_WIDTH = 280;

const instrument = localStorage.getItem('instrument') ? JSON.parse(localStorage.getItem('instrument') as string) : null;
const score = {
    title: 'Partitions',
    path: instrument != null ? (instrument.link != null ? instrument.link : null) : null, 
    // @ts-ignore
    icon: <SvgColor src={'/assets/icons/navbar/ic_external.svg'} sx={{ width: 1, height: 1 }} />,
}
const IS_SCORE_LINK = instrument != null ? (instrument.link != null) : false;

if (IS_SCORE_LINK) navConfigPublic.push(score);

// ---

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }: any) {
    const { pathname } = useLocation();

    const userIsAdmin = getRole() == 1;
    const userIsBureau = getRole() == 2 || getRole() == 1;

    const isDesktop = useResponsive('up', 'lg');

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
                <Logo />
                <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}>
                    <Typography sx={{ fontWeight: 600 }}>
                        Espace Musiciens
                    </Typography>
                </Box>
            </Box>

            <NavSection title="Musicien" data={navConfigPublic} />

            {userIsBureau &&
                <NavSection title="Bureau" data={navConfigBureau} />
            }

            {userIsAdmin &&
                <NavSection title="Administration" data={navConfigAdmin} />
            }

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ mt: 10 }}>
                
            </Box>
        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV_WIDTH },
            }}
        >
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}