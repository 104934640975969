import { useState } from 'react';

import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, IconButton, Popover, ListItemButton } from '@mui/material';
import Avatar from "boring-avatars";

import { logout } from '../../../api/auth';
import { Navigate } from 'react-router';
import { useSWRConfig } from 'swr';

// ---

const MENU_OPTIONS = [
    {
        label: 'Profil',
        icon: 'eva:person-fill',
    },
];

// ---

export default function AccountPopover() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const [open, setOpen] = useState(null);
    const [toProfile, setToProfile] = useState(false);
    const { mutate } = useSWRConfig()

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogout = async () => {
        handleClose();
        mutate(
            key => true, // quelles clés de cache sont mises à jour
            undefined, // mettre à jour les données du cache avec `undefined`
            { revalidate: false } // ne pas revalider les données
        )
        await logout();
    }

    if (toProfile) {
        return <Navigate to="/profile" />;
    }

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar
                    width={45}
                    name={`${user.last_name} ${user.first_name}`}
                    variant='beam'
                    colors={['#CA2E55', '#00B8D9', '#22C55E', '#FFAB00', '#FF5630']}
                />
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user.first_name} {user.last_name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    {/* {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))} */}
                    <ListItemButton to="/profile" sx={{ borderRadius: '4.5px' }}>
                        Profil
                    </ListItemButton>
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1, color: 'error.main' }} >
                    Déconnexion
                </MenuItem>
            </Popover>
        </>
    );
}
