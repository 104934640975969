import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack'

import ThemeProvider from './theme';

import Router from './routes';
import { SWRConfig } from 'swr';

import * as Sentry from "@sentry/react";

import { DSN_URL, VERSION } from './constant';
import React from 'react';

import TimeAgo from 'javascript-time-ago'
import fr from 'javascript-time-ago/locale/fr'
TimeAgo.addDefaultLocale(fr);


function localStorageProvider() {
	// Pendant l'initialisation, nous restaurons les données de `localStorage` dans une map.
	const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'))

	// Avant de décharger l'application, nous réécrivons toutes les données dans `localStorage`. Sauf si l'utilisateur vient de se déconnecter.
	window.addEventListener('beforeunload', () => {
		if (localStorage.getItem('token')) {
			const appCache = JSON.stringify(Array.from(map.entries()))
			localStorage.setItem('app-cache', appCache)
		}
	})

	// Nous utilisons la map comme cache pour écrire et lire pour des raisons de performance.
	return map
}


if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: DSN_URL,
		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: ["localhost", /^https:\/\/api\.orchestremontois\.fr/],
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				),
			}),
			new Sentry.Replay({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		tracePropagationTargets: ["localhost", /^https:\/\/api\.orchestremontois\.fr/],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		release: "orchestre-montois_musiciens@" + VERSION,
	});
}

function App() {
	return (
		// @ts-ignore
		<SWRConfig value={{ provider: localStorageProvider }}>
			<HelmetProvider>
				<BrowserRouter>
					<ThemeProvider>
						<SnackbarProvider />
						<Router />
					</ThemeProvider>
				</BrowserRouter>
			</HelmetProvider>
		</SWRConfig>
	);
}

export default App;
