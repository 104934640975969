// @ts-nocheck
import { Alert, AlertTitle, Autocomplete, Box, Breadcrumbs, Button, Card, CardActions, CardContent, Checkbox, Container, Divider, FormControl, FormControlLabel, Grid, InputLabel, Link, ListItemText, MenuItem, Select, Skeleton, Stack, Tab, TabScrollButton, Tabs, TextField, Typography, styled } from "@mui/material";
import Avatar from "boring-avatars";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async"
import Iconify from "../../components/iconify/Iconify";
import Label from "../../components/label/Label";
import { Navigate } from "react-router";
import useSWR from "swr";
import { fetcher, instance } from "../../api";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateEmailValidationSchema, UpdatePasswordValidationSchema } from "../../utils/yupValidation";
import { updateUserEmail, updateUserPassword } from "../../api/user";

import { enqueueSnackbar } from 'notistack';
import { TITLE } from "../../constant";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import { retrieveErrorMessages } from "../../utils/errorsDisplay";

// ---

const HeaderTitle = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		marginBottom: '40px',
	},
	[theme.breakpoints.down('md')]: {
		marginBottom: '24px',
	},
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
	'& .MuiTabs-indicator': {
		backgroundColor: theme.palette.primary.main,
	},
	'& .MuiTabs-flexContainer': {
		paddingRight: '24px',
		justifyContent: 'flex-end',
	}
}));

// @ts-ignore
const instruments = (JSON.parse(localStorage.getItem('instruments')) || []).sort((a, b) => a.name.localeCompare(b.name));

// ---

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`attendance-management-details-tabpanel-${index}`}
			aria-labelledby={`attendance-management-details-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `attendance-management-details-tab-${index}`,
		'aria-controls': `attendance-management-details-tabpanel-${index}`,
	};
}

// ---

function UpdatePasswordForm() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(UpdatePasswordValidationSchema),
	});

	const handleEditPassword = (data: any) => {
		updateUserPassword(data)
			.then((response) => {
				enqueueSnackbar('Votre mot de passe a bien été modifié.', { variant: 'success' });
				localStorage.setItem('user', JSON.stringify(response.data));
				return;
			}).catch((error) => {
				enqueueSnackbar('Une erreur est survenue lors de la modification de votre mot de passe. (' + error.response?.data.error + ')', { variant: 'error' });
				return;
			});
	}

	return (
		<form onSubmit={handleSubmit(handleEditPassword)} >
			<Stack direction="column" spacing={2} sx={{ marginTop: 3 }}>
				<TextField
					id="old_password"
					label="Ancien mot de passe"
					variant="outlined"
					fullWidth
					type="password"
					{...register('old_password')}
					error={!!errors.old_password}
					helperText={errors.old_password?.message}
				/>
				<TextField
					id="password"
					label="Nouveau mot de passe"
					variant="outlined"
					fullWidth
					type="password"
					{...register('password')}
					error={!!errors.password}
					helperText={errors.password?.message}
				/>
				<TextField
					id="confirmation_password"
					label="Confirmer le nouveau mot de passe"
					variant="outlined"
					fullWidth
					type="password"
					{...register('confirmation_password')}
					error={!!errors.confirmation_password}
					helperText={errors.confirmation_password?.message}
				/>
			</Stack>

			<Button sx={{ marginTop: 3 }} variant="contained" color="primary" fullWidth type="submit">Modifier</Button>
		</form>
	)
}

function UpdateEmailForm() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(UpdateEmailValidationSchema),
	});

	const handleEditEmail = (data: any) => {
		updateUserEmail(data)
			.then((response) => {
				enqueueSnackbar('Votre adresse email a bien été modifiée.', { variant: 'success' });
				localStorage.setItem('user', JSON.stringify(response.data));
				return;
			}).catch((error) => {
				enqueueSnackbar('Une erreur est survenue lors de la modification de votre adresse email. (' + error.response?.data.error + ')', { variant: 'error' });
				return;
			});
	}

	return (
		<form onSubmit={handleSubmit(handleEditEmail)}>
			<Stack direction="column" spacing={2} sx={{ marginTop: 3 }}>
				<TextField
					id="email"
					label="Nouvelle email"
					variant="outlined"
					fullWidth
					type="email"
					{...register('email')}
					error={!!errors.email}
					helperText={errors.email?.message}
				/>
				<TextField
					id="confirmation_email"
					label="Confirmer la nouvelle email"
					variant="outlined"
					fullWidth
					type="email"
					{...register('confirmation_email')}
					error={!!errors.confirmation_email}
					helperText={errors.confirmation_email?.message}
				/>
			</Stack>

			<Button sx={{ marginTop: 3 }} variant="contained" color="primary" fullWidth type="submit">Modifier</Button>
		</form>
	)
}

function AccountProfile() {
	const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null;

	const [emailNotification, setEmailNotification] = useState(user.email_notifications);
	const [pushNotification, setPushNotification] = useState(user.push_notifications);

	const changeEmailNotification = (checked: boolean) => {
		instance.put('/profile/user/emailNotification', { email_notifications: checked })
			.then((response) => {
				setEmailNotification(checked);
				localStorage.setItem('user', JSON.stringify(response.data.data));
				enqueueSnackbar('Vos préférences de notification ont bien été modifiées.', { variant: 'success' });
				return;
			}).catch((error) => {
				enqueueSnackbar('Une erreur est survenue lors de la modification de vos préférences de notification. (' + error.response?.data.error + ')', { variant: 'error' });
				return;
			});
	}

	const changePushNotification = (checked: boolean) => {
		instance.put('/profile/user/pushNotification', { push_notifications: checked })
			.then((response) => {
				setPushNotification(checked);
				localStorage.setItem('user', JSON.stringify(response.data.data));
				enqueueSnackbar('Vos préférences de notification ont bien été modifiées.', { variant: 'success' });
				return;
			}).catch((error) => {
				enqueueSnackbar('Une erreur est survenue lors de la modification de vos préférences de notification. (' + error.response?.data.error + ')', { variant: 'error' });
				return;
			});
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={3}>
				<Stack gap={2} direction="column">
					<Card>
						<CardContent>
							<Typography variant="h6" gutterBottom>
								Informations
							</Typography>

							<Stack direction="column" spacing={2}>
								<Stack direction="row" spacing={2}>
									<Iconify icon="solar:user-circle-bold" width={20} />
									{/* @ts-ignore */}
									<Typography variant="body2">{user.email}</Typography>
								</Stack>

								<Stack direction="row" spacing={2}>
									<Iconify icon="solar:verified-check-bold" width={20} />
									{/* @ts-ignore */}
									<Typography variant="body2"><Label color={user.email_verified_at ? 'success' : 'error'}>{user.email_verified_at ? 'Vérifié' : 'Non vérifié'}</Label> {user.email_verified_at ? 'le ' + new Date(user.email_verified_at).toLocaleDateString('fr-FR', {
										weekday: 'long',
										year: 'numeric',
										month: 'short',
										day: 'numeric'
									}) : null}</Typography>
								</Stack>

								<Stack direction="row" spacing={2}>
									<Iconify icon="solar:home-add-angle-bold" width={20} />
									{/* @ts-ignore */}
									<Typography variant="body2">Créé le {new Date(user.created_at).toLocaleDateString('fr-FR', {
										weekday: 'long',
										year: 'numeric',
										month: 'short',
										day: 'numeric',
									})}</Typography>
								</Stack>

								<Stack direction="row" spacing={2}>
									<Iconify icon="solar:document-add-bold" width={20} />
									{/* @ts-ignore */}
									<Typography variant="body2">Modifié le {new Date(user.updated_at).toLocaleDateString('fr-FR', {
										weekday: 'long',
										year: 'numeric',
										month: 'short',
										day: 'numeric',
										hour: 'numeric',
										minute: 'numeric',
									})}</Typography>
								</Stack>
							</Stack>
						</CardContent>
					</Card>

					<Card>
						<CardContent>
							<Typography variant="h6" gutterBottom>
								Notifications
							</Typography>

							<Stack direction="column" gap={2}>
								<FormControlLabel
									sx={{ width: '100%' }}
									control={<Checkbox />}
									label="Par Email"
									checked={emailNotification}
									// @ts-ignore
									onChange={(e: ChangeEvent<{ checked: boolean }>) => changeEmailNotification(e.target.checked)}
								/>

								<FormControlLabel
									sx={{ width: '100%' }}
									control={<Checkbox />}
									label="Par Notification"
									checked={pushNotification}
									// @ts-ignore
									onChange={(e: ChangeEvent<{ checked: boolean }>) => changePushNotification(e.target.checked)}
								/>
							</Stack>
						</CardContent>
					</Card>
				</Stack>
			</Grid>

			<Grid item xs={12} md={5}>
				<Card>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							Modifier l'email
						</Typography>

						<UpdateEmailForm />
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12} md={4}>
				<Card>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							Modifier le mot de passe
						</Typography>

						<UpdatePasswordForm />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)
}

// ---

function PersonalInformationUpdateCard({ isLoading, error, data }) {
	const [addressAutocompleteOptions, setAddressAutocompleteOptions] = useState([]);
	const [birthDateValue, setBirthDateValue] = useState(new Date());
	const previousController = useRef(null);

	const [phoneError, setPhoneError] = useState(false);

	useEffect(() => {
		if (!isLoading) {
			setBirthDateValue(new Date(data.birthday));
		}
	}, [isLoading]);

	const getData = (searchTerm: string) => {
		const controller = new AbortController();
		const signal = controller.signal;
		previousController.current = controller;

		if (searchTerm.length < 3) {
			setAddressAutocompleteOptions([]);
			return;
		}

		fetch(`https://api-adresse.data.gouv.fr/search/?q=${searchTerm}&autocomplete=1`, {
			signal,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json"
			}
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (myJson) {
				const updatedOptions = myJson.features.map((adr) => {
					return {
						label: adr.properties.label,
						street: adr.properties.name,
						city: adr.properties.city,
						zipCode: adr.properties.postcode,
					};
				});
				setAddressAutocompleteOptions(updatedOptions);
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	const getOptionLabel = useCallback(
		(option: PlaceType) => (typeof option === 'string' ? option : option.label),
		[]
	);

	const addressAutocompleteChange = (event, value, reason) => {
		if (value) {
			getData(value);
		} else {
			setAddressAutocompleteOptions([]);
		}
	};

	const submitForm = (e) => {
		e.preventDefault();

		const formData = new FormData(e.target);

		const data = {
			phone: formData.get('phone'),
			address: addressAutocompleteOptions[0].street,
			zip: addressAutocompleteOptions[0].zipCode,
			city: addressAutocompleteOptions[0].city,
			birthday: birthDateValue,
		};

		// Check if phone is valid
		if (data.phone === undefined || data.phone.length < 10) {
			setPhoneError(true);
			enqueueSnackbar('Veuillez saisir un numéro de téléphone valide.', { variant: 'error' });
			return;
		} else {
			setPhoneError(false);
		}

		// Check if address is valid
		if (data.address === undefined || data.zip === undefined || data.city === undefined) {
			enqueueSnackbar('Veuillez sélectionner une adresse valide.', { variant: 'error' });
			return;
		}

		// Check if birthday is valid
		if (data.birthday === undefined) {
			enqueueSnackbar('Veuillez sélectionner une date de naissance valide.', { variant: 'error' });
			return;
		}

		instance.put('/profile/member', data)
			.then((response) => {
				enqueueSnackbar('Vos informations ont bien été modifiées.', { variant: 'success' });
				return;
			}).catch((error) => {
				enqueueSnackbar(retrieveErrorMessages(error?.code, error?.message), { variant: 'error' });
				return;
			});
	}

	return (
		<Card>
			<CardContent>
				<form onSubmit={submitForm}>
					<Typography variant="h6" gutterBottom>
						Informations personnelles
					</Typography>
					<Stack spacing={2}>
						{!isLoading && !error && (
							<>
								<TextField
									fullWidth
									label="Numéro de téléphone"
									variant="outlined"
									name="phone"
									defaultValue={data?.phone}
									error={phoneError}
									helperText={phoneError ? 'Veuillez saisir un numéro de téléphone valide.' : ''}
								/>
								<Autocomplete
									id="address"
									autoComplete
									filterOptions={(x) => x}
									filterSelectedOptions
									getOptionLabel={getOptionLabel}
									isOptionEqualToValue={(option, value) => option.label === value.label}
									onInputChange={addressAutocompleteChange}
									options={addressAutocompleteOptions}
									defaultValue={`${data.address}, ${data.zip}, ${data.city}`}
									renderInput={(params) =>
										<TextField
											{...params}
											label="Adresse"
											value={`${data.address}, ${data.zip}, ${data.city}`}
										/>}
									noOptionsText="Aucune adresse trouvée"
									autoHighlight={true}
								>
								</Autocomplete>
								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
									<DatePicker
										label="Date de naissance"
										disableFuture
										id="birthDate"
										openTo="year"
										value={new Date(birthDateValue)}
										views={['year', 'month', 'day']}
										onChange={(newValue) => {
											setBirthDateValue(newValue);
										}}
										sx={{ width: '100%' }}
									/>
								</LocalizationProvider>
							</>
						)}

						{isLoading && (
							<>
								<Skeleton variant="text" />
								<Skeleton variant="text" />
								<Skeleton variant="text" />
							</>
						)}
					</Stack>

					<Button sx={{ marginTop: 3 }} variant="contained" color="primary" fullWidth type="submit">Modifier</Button>
				</form>
			</CardContent>
		</Card>
	)
}

function MoreInformationUpdateCard({ isLoading, error, data }) {
	const [shirtSize, setShirtSize] = useState('');

	useEffect(() => {
		if (!isLoading) {
			setShirtSize(data.shirt_size);
		}
	}, [isLoading]);

	const submitForm = (e) => {
		e.preventDefault();

		const formData = new FormData(e.target);

		const data = {
			shirt_size: shirtSize,
			instrument_reference: formData.get('instrumentReference'),
			emergency_contact: formData.get('emergencyContact'),
			other_emails: formData.get('otherEmail'),
		};

		instance.put('/profile/member', data)
			.then((response) => {
				enqueueSnackbar('Vos informations ont bien été modifiées.', { variant: 'success' });
				console.log(response);
				return;
			}).catch((error) => {
				enqueueSnackbar(retrieveErrorMessages(error?.code, error?.message), { variant: 'error' });
				return;
			});
	}

	return (
		<Card>
			<CardContent>
				<form onSubmit={(e) => submitForm(e)}>
					<Typography variant="h6" gutterBottom>
						Informations supplémentaires
					</Typography>
					<Stack spacing={2}>
						{!isLoading && !error && (
							<>
								<FormControl fullWidth>
									<InputLabel id="shirt-size-label">Taille T-Shirt / Chemise</InputLabel>
									<Select
										labelId="shirt-size-label"
										id="shirt-size"
										value={shirtSize}
										label="Taille T-Shirt / Chemise"
										onChange={(e) => setShirtSize(e.target.value)}
									>
										<MenuItem value="XSS">XXS</MenuItem>
										<MenuItem value="XS">XS</MenuItem>
										<MenuItem value="S">S</MenuItem>
										<MenuItem value="M">M</MenuItem>
										<MenuItem value="L">L</MenuItem>
										<MenuItem value="XL">XL</MenuItem>
										<MenuItem value="XXL">XXL</MenuItem>
										<MenuItem value="3XL">3XL</MenuItem>
										<MenuItem value="4XL">4XL</MenuItem>
										<MenuItem value="5XL">5XL</MenuItem>
									</Select>
								</FormControl>
								<TextField
									fullWidth
									name="instrumentReference"
									label="Référence instrument(s)"
									variant="outlined"
									multiline
									defaultValue={data.instrument_reference}
								/>
								<TextField
									fullWidth
									name="emergencyContact"
									label="Contact d'urgence"
									variant="outlined"
									multiline
									defaultValue={data.emergency_contact}
								/>
								<TextField
									fullWidth
									name="otherEmail"
									label="Autres emails pour la mailing-list"
									variant="outlined"
									multiline
									defaultValue={data.other_email}
								/>
							</>
						)}

						{isLoading && (
							<>
								<Skeleton variant="text" />
								<Skeleton variant="text" />
								<Skeleton variant="text" />
								<Skeleton variant="text" />
							</>
						)}
					</Stack>

					<Button sx={{ marginTop: 3 }} variant="contained" color="primary" fullWidth type="submit">Modifier</Button>
				</form>
			</CardContent>
		</Card>
	)
}

function MemberProfile() {
	const { data, error, isLoading } = useSWR('/profile/member', fetcher);

	const [unavailable, setUnavailable] = useState(isLoading ? false : data.unavailable);

	useEffect(() => {
		if (!isLoading) {
			setUnavailable(data.unavailable);
		}
	}, [isLoading]);

	const changeUnavailableState = (checked: boolean) => {
		instance.put('/profile/member/unavailable', { unavailable: checked })
			.then((response) => {
				setUnavailable(checked);
				enqueueSnackbar('Votre disponibilité a bien été modifiée.', { variant: 'success' });
				return;
			}).catch((error) => {
				enqueueSnackbar('Une erreur est survenue lors de la modification de votre disponibilité. (' + error.response?.data.error + ')', { variant: 'error' });
				return;
			});
	}

	return (
		<>
			{error && (
				<Alert sx={{ mb: 2 }} severity="error">
					<AlertTitle>Impossible de récupérer vos informations</AlertTitle>
					Il nous est actuellement impossible de récuper vos informations pour le moment. Veuillez réessayer plus tard.
				</Alert>
			)}

			<Grid container spacing={2}>
				<Grid item xs={12} md={3}>
					<Stack direction="column" spacing={2}>
						<Card>
							<CardContent>
								<Typography variant="h6" gutterBottom>
									Informations
								</Typography>

								<Stack direction="column" spacing={2}>
									{!isLoading && !error && (
										<>
											<Stack direction="row" spacing={2}>
												<Iconify icon="solar:mention-circle-bold" width={20} />
												{/* @ts-ignore */}
												<Typography variant="body2">{data.email}</Typography>
											</Stack>

											<Stack direction="row" spacing={2}>
												<Iconify icon="solar:accessibility-bold" width={20} />
												<Typography variant="body2">{data.gender == 'male' ? 'Homme' : 'Femme'}</Typography>
											</Stack>

											<Stack direction="row" spacing={2}>
												<Iconify icon="solar:calendar-bold" width={20} />
												<Typography variant="body2">Membre depuis le {!isLoading && (new Date(data.member_since).toLocaleDateString('fr-FR', {
													year: 'numeric',
													month: 'long',
													day: 'numeric'
												}))}</Typography>
											</Stack>

											<Stack direction="row" spacing={2}>
												<Iconify icon="solar:document-add-bold" width={20} />
												{/* @ts-ignore */}
												<Typography variant="body2">Modifié le {new Date(data.updated_at).toLocaleDateString('fr-FR', {
													weekday: 'long',
													year: 'numeric',
													month: 'short',
													day: 'numeric',
													hour: 'numeric',
													minute: 'numeric',
												})}</Typography>
											</Stack>
										</>
									)}
								</Stack>
							</CardContent>
						</Card>

						<Card>
							<CardContent>
								<Typography variant="h6" gutterBottom>
									Options
								</Typography>

								<Stack direction="column" gap={2}>
									<FormControlLabel
										sx={{ width: '100%' }}
										control={<Checkbox />}
										label="Absent de l'orchestre"
										checked={unavailable}
										onChange={(e: ChangeEvent<{ checked: boolean }>) => changeUnavailableState(e.target.checked)}
									/>
								</Stack>
							</CardContent>
						</Card>
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<PersonalInformationUpdateCard isLoading={isLoading} error={error} data={data} />
				</Grid >

				<Grid item xs={12} md={6}>
					<MoreInformationUpdateCard isLoading={isLoading} error={error} data={data} />
				</Grid>
			</Grid>
		</>
	)
}

function Profile() {
	const { data, error, isLoading } = useSWR('/profile/member', fetcher);
	const [tabValue, setTabValue] = useState(1);

	const member = data ? data : null;
	const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null;

	if (!user) {
		return <Navigate to="/" />;
	}

	return (
		<>
			<Helmet>
				<title>Profil {TITLE}</title>
			</Helmet>

			<Container maxWidth="xl">
				<HeaderTitle>
					<Box>
						<Typography variant="h4" gutterBottom>
							Profil
						</Typography>
						<Breadcrumbs separator="›" aria-label="breadcrumb">
							<Link underline="hover" color="inherit" href="/">
								Accueil
							</Link>
							<Link underline="hover" color="inherit" href="/attendance">
								Utilisateur
							</Link>
							<Typography color="text.primary">
								{user.last_name} {user.first_name}
							</Typography>
						</Breadcrumbs>
					</Box>
				</HeaderTitle>

				<Card
					sx={{
						backgroundColor: 'white',
						color: 'grey.800',
						backgroundImage: 'none',
						overflow: 'hidden',
						position: 'relative',
						height: '290px',
						marginBottom: '24px',
						zIndex: 0,
					}}
				>
					<Box
						sx={{
							height: '100%',
							color: 'white',
							backgroundImage: 'url("./assets/images/profile_bg.jpg")',
							backgroundPosition: 'center center',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
						}}
					>
						<Stack
							sx={{
								left: '24px',
								bottom: '24px',
								zIndex: 10,
								paddingTop: 0,
								position: 'absolute',
							}} direction="row"
						>
							<Avatar
								size={124}
								name={`${user.last_name} ${user.first_name}`}
								variant="beam"
								colors={['#CA2E55', '#00B8D9', '#22C55E', '#FFAB00', '#FF5630']}
							/>
							<ListItemText sx={{ marginTop: '24px', marginLeft: '24px' }}>
								<Typography variant="h5">{user.last_name} {user.first_name}</Typography>
								{/* @ts-ignore */}
								<Typography variant="body1" color="grey.400">{!isLoading && !error && instruments.find((value) => { return value.id === member.instrument_id })?.name}</Typography>
							</ListItemText>
						</Stack>
					</Box>
					<StyledTabs
						sx={{
							overflow: 'hidden',
							minHeight: '48px',
							display: 'flex',
							width: '100%',
							bottom: '0px',
							zIndex: 9,
							position: 'absolute',
							backgroundColor: 'white',
						}}
						variant="scrollable"
						scrollButtons="auto"
						value={tabValue}
					>
						<Tab sx={{ minHeight: '48px' }} icon={<Iconify icon="solar:user-rounded-bold" />} iconPosition="start" label="Membre" value={1} onClick={() => { setTabValue(1) }} />
						<Tab sx={{ minHeight: '48px' }} icon={<Iconify icon="solar:user-id-bold" />} iconPosition="start" label="Compte" value={2} onClick={() => { setTabValue(2) }} />
					</StyledTabs>
				</Card>

				<TabPanel value={tabValue} index={1}>
					<MemberProfile />
				</TabPanel>

				<TabPanel value={tabValue} index={2}>
					<AccountProfile />
				</TabPanel>

			</Container>
		</>
	)
}

export default Profile